import React from 'react'
import PropTypes from 'prop-types'

import WorkLayout from '../components/layouts/WorkLayout'

import { graphql, Link } from 'gatsby'

import { ChevronLeftIcon } from '@heroicons/react/solid'

import Badge from '../components/Badge'
import Video from '../components/Video'
import WorkDetailsBanner from '../components/WorkDetailsBanner'
import SearchEngineOptimisation from '../components/SearchEngineOptimisation'


const WorkDetail = ({ data }) => {
	const { html } = data.markdownRemark;
	const { 
		badges, 
		bannerImage,  
		awardImage,  
		awardImageAlt,
		client, 
		sector, 
		bannerHeading, 
		intro, 
		youtubeVideoID, 
		youtubeTitle, 
		title
	} = data.markdownRemark.frontmatter;

	const keywords = badges.map((badge, i) => {										
		return (badge['badge']);
	});

	return (
		<WorkLayout>
			<SearchEngineOptimisation title={`Tobias Work - ${title}`} kwords={keywords.toString()} />
			<main id="content" role="main">
				<div className="isolate bg-black">
					<div className="container h-quarter-screen lg:h-half-screen relative overflow-hidden">						
            <WorkDetailsBanner 
							image={ bannerImage } 
							title={ bannerHeading } 
							sector={ sector } 
							client={ client } 
							awardImage={ awardImage }
							awardImageAlt={ awardImageAlt }
						/>
          </div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6 border-t-5 border-yellow">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<Link to="/work" className="focus:outline-none focus:ring-4 focus:ring-yellow md:-ml-12 -ml-8 uppercase text-gray-500 font-bold text-sm pb-1 underline ">
								<ChevronLeftIcon className="md:w-12 md:h-12 h-8 w-8 inline md:pr-1 pr-0 text-gray-900" />Work
								</Link>
							</div>
						</div>
					</div>
				</div>

				{ intro &&
				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<p className="md:text-2xl sm:text-xl text-lg font-light">{ intro }</p>
							</div>
						</div>
					</div>
				</div>
				}

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								{
									badges && 
									badges.map((badge, i) => {
										
										return (
											<Badge key={`badge-${i}`}>{ badge['badge'] }</Badge>
										);
									})
								}
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
            		<hr />
							</div>
						</div>
          </div>
				</div>

				{
				youtubeVideoID && 

					<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
						<div className="container">
							<div className="grid grid-cols-12 gap-6">
								<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
									
										<Video 
											videoSrcURL={`https://www.youtube.com/embed/${youtubeVideoID}`}
											videoTitle={ youtubeTitle } 
										/>
									
								</div>

								<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12 lg:pt-12 md:pt-8 pt-6">
									<hr />
								</div>

							</div>
						</div>
					</div>
				}

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<div className="case-study" dangerouslySetInnerHTML={{ __html: html }} />
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
            		<hr />
							</div>
						</div>
          </div>
				</div>

				<div className="isolate bg-white lg:pt-12 lg:pb-12 md:pt-8 md:pb-8 pt-6 pb-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								{
									badges && 
									badges.map((badge, i) => {
										
										return (
											<Badge key={`badge-${i}`}>{ badge['badge'] }</Badge>
										);
									})
								}
							</div>
						</div>
					</div>
				</div>

			

			</main>
		</WorkLayout>
	)
}

export default WorkDetail;

WorkDetail.propTypes = {
  data: PropTypes.shape({
		html: PropTypes.object,
    markdownRemark: PropTypes.object,
  }),
}

export const workDetailPageQuery = graphql`
query workDetailPage($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      badges {
        badge
      }
      bannerImage 
			awardImage
			awardImageAlt
      client
      sector
			intro
      bannerHeading
      youtubeVideoID
      youtubeTitle
			title
    }
  }
}
`