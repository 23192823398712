import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const AwardImg = ({image, imageAlt }) => {
	const passedAwardImg = image.substring((image.lastIndexOf('/')+1)) || null;

	let awardImgObj;

	const imgData = useStaticQuery(graphql`
	query awardImageObjects {
		allFile(filter: {sourceInstanceName: {eq: "workAwardImages"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							width: 160, 
							placeholder: BLURRED, 
							formats: [AUTO, WEBP, AVIF])
					}
					relativePath
				}
			}
		}
	}
  `);

	for (let i = 0; i < imgData.allFile.edges.length; i++) {
		if (passedAwardImg && imgData.allFile.edges[i].node.relativePath === passedAwardImg) {
			awardImgObj = imgData.allFile.edges[i].node;
			break;
		}
	}

	let awardImg = getImage(awardImgObj);

	return (
		<GatsbyImage image={awardImg} style={{position: 'absolute !important'}} className="absolute top-2 right-0 lg:w-40 md:w-28 sm:w-20 w-16" alt={ imageAlt } />
	)
}

export default AwardImg;