import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import AwardImage from './AwardImage'

const WorkDetailsBanner = ({image, title, sector, client, awardImage, awardImageAlt }) => {
	
	// Get file from path
	const passedBannerImg = image.substring((image.lastIndexOf('/')+1));

	let bannerImgObj;

	const imgData = useStaticQuery(graphql`
	query bannerImageObjects {
		allFile(filter: {sourceInstanceName: {eq: "workBanners"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							width: 1450, 
							placeholder: BLURRED, 
							formats: [AUTO, WEBP, AVIF])
					}
					relativePath
				}
			}
		}
	}
  `);

	for (let i = 0; i < imgData.allFile.edges.length; i++) {
		if (imgData.allFile.edges[i].node.relativePath === passedBannerImg) {
			bannerImgObj = imgData.allFile.edges[i].node;
		}
	}

	const backgroundImg = getImage(bannerImgObj);
	const bgImage = convertToBgImage(backgroundImg);

	return (
		<BackgroundImage className="h-full" Tag="div" {...bgImage}>
		
				<div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
				<div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
				<div className="grid grid-cols-12 absolute top-0 w-full h-full">
					<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
						<div className="flex flex-col h-full">
							<div className="flex flex-grow w-full items-end">
								<h1 className="bg-black lg:bg-opacity-75 sm:bg-opacity-60 bg-opacity-50 text-white uppercase lg:text-3xl md:text-2xl text-xl">{ title }</h1> 
							</div>
							<div className="flex-shrink py-4">
								<p className="text-white md:text-sm text-xs">
									Client: <strong>{ client }</strong><br />
									Sector: <strong>{ sector }</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			{
				awardImage && 
				<AwardImage image={awardImage} imageAlt={ awardImageAlt } />
			}
		</BackgroundImage>
	)
}

export default WorkDetailsBanner

