import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => {
	return (
		<div className="relative h-0" style={{paddingBottom: '56.25%'}}>
			<iframe 
				className="w-full h-full absolute top-0 left-0"
				src={videoSrcURL}
				title={videoTitle}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		</div>
	)
}
export default Video