import React from 'react';

const Badge = ({children}) => {
	return (
		<span className="whitespace-nowrap mt-2 mr-4 text-center py-1 px-4 bg-gray-200 rounded w-auto text-sm text-gray-700 inline-block">
			{children}	
		</span>
	)
}

export default Badge;
